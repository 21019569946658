// ** Import server Config
import { serverConfig } from '@configs/serverConfig'

// ** Auth Endpoints
export default {
  loginEndpoint: `${serverConfig.path}/api/auth/admin/login`,
  refreshEndpoint: `${serverConfig.path}/api/auth/admin/refresh-token`,
  registerEndpoint: `${serverConfig.path}/api/auth/admin/register`,
  logoutEndpoint: `${serverConfig.path}/api/auth/admin/logout`,

  tokenType: 'Bearer',

  storageTokenKeyName: 'token',
  storageRefreshTokenKeyName: 'refreshToken'
}
