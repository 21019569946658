// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Import server Config
import apiLink from '@configs/serverConfig'

// ** Axios Imports
import axios from 'axios'

export const getExpectedCreditPayments = createAsyncThunk('appFinances/getExpectedCreditPayments', async params => {
    const response = await axios.get(apiLink('getCreditsList', params))
    return response.data.credits
})
export const getExpectedRentPayments = createAsyncThunk('appFinances/getExpectedRentPayments', async params => {
    const response = await axios.get(apiLink('getRentsList', params))
    return response.data.rents
})

export const getExpectedRentVisits = createAsyncThunk('appFinances/getExpectedRentVisits', async data => {
    const response = await axios.post(`http://api.mfauto.com.ua/rent/expected-visits?env=${process.env.REACT_APP_NODE_ENV}`, data)
    return response.data
})
export const getExpiredRentVisits = createAsyncThunk('appFinances/getExpiredRentVisits', async data => {
    const response = await axios.post(`http://api.mfauto.com.ua/rent/expired-visits?env=${process.env.REACT_APP_NODE_ENV}`, data)
    return response.data
})
export const getExpectedCreditVisits = createAsyncThunk('appFinances/getExpectedCreditVisits', async data => {
    const response = await axios.post(`http://api.mfauto.com.ua/credit/expected-visits?env=${process.env.REACT_APP_NODE_ENV}`, data)
    return response.data
})
export const getExpiredCreditVisits = createAsyncThunk('appFinances/getExpiredCreditVisits', async data => {
    const response = await axios.post(`http://api.mfauto.com.ua/credit/expired-visits?env=${process.env.REACT_APP_NODE_ENV}`, data)
    return response.data
})
export const getExpectedRentVisitsPredictions = createAsyncThunk('appFinances/getExpectedRentVisitsPredictions', async data => {
    const response = await axios.post(`http://api.mfauto.com.ua/rent/expected-visits-predictions?env=${process.env.REACT_APP_NODE_ENV}`, data)
    return response.data
})
export const getExpectedCreditVisitsPredictions = createAsyncThunk('appFinances/getExpectedCreditVisitsPredictions', async data => {
    const response = await axios.post(`http://api.mfauto.com.ua/credit/expected-visits-predictions?env=${process.env.REACT_APP_NODE_ENV}`, data)
    return response.data
})
export const getRentEvents = createAsyncThunk('appFinances/getRentEvents', async data => {
    const response = await axios.post(`http://api.mfauto.com.ua/rent/events?env=${process.env.REACT_APP_NODE_ENV}`, data)
    return response.data
})
export const getRentMissedVisits = createAsyncThunk('appFinances/getRentMissedVisits', async data => {
    const response = await axios.post(`http://api.mfauto.com.ua/rent/missed-visits?env=${process.env.REACT_APP_NODE_ENV}`, data)
    return response.data
})
export const getCreditMissedVisits = createAsyncThunk('appFinances/getCreditMissedVisits', async data => {
    const response = await axios.post(`http://api.mfauto.com.ua/credit/missed-visits?env=${process.env.REACT_APP_NODE_ENV}`, data)
    return response.data
})

export const getSystemPayments = createAsyncThunk('appFinances/getSystemPayments', async params => {
    params.page = params.page === 0 ? 0 : params.page - 1

    const response = await axios.get(apiLink('getPaymentsList', params))
    return {
        params,
        payments: response.data.response,
        totalPages: response.data.total_elements
    }
})

// ** Payments
export const addPayment = createAsyncThunk('appFinances/addPayment', async (data) => {
    return await axios.post(apiLink('addPayment'), data)
})
export const removePayment = createAsyncThunk('appFinances/removePayment', async (id) => {
    const response = await axios.delete(apiLink({name: 'removePayment', value: id}, {type: 'COMPANY'}))
    return response.data
})

// ** Payment settings list/subtypes
export const getPaymentSubTypes = createAsyncThunk('appFinances/getPaymentSubTypes', async () => {
    const response = await axios.get(apiLink('getPaymentSubTypes'))
    return response.data
})
export const addPaymentSubType = createAsyncThunk('appFinances/addPaymentSubType', async (data) => {
    return await axios.post(apiLink('addPaymentSubTypes'), data)
})
export const updatePaymentSubTypes = createAsyncThunk('appFinances/updatePaymentSubTypes', async (data) => {
    const subtype_id = data.id
    delete data.id
    const response = await axios.put(apiLink({name: 'updatePaymentSubTypes', value: subtype_id}), data)
    return response.data
})
export const removePaymentSubTypes = createAsyncThunk('appFinances/removePaymentSubTypes', async (id) => {
    const response = await axios.delete(apiLink({name: 'removePaymentSubTypes', value: id}))
    return response.data
})

export const approvePayment = createAsyncThunk('appFinances/approvePayment', async (data) => {    
    const  response = await axios.post(apiLink({name: 'approvePayment', value: data.payment_id}), data)
    return {response, data}
})

// ** Notifications
export const getNofiticationsExpected = createAsyncThunk('appFinances/getNofiticationsExpected', async data => {   
    const response = await axios.post(`http://api.mfauto.com.ua/notifications/expected-visits?env=${process.env.REACT_APP_NODE_ENV}`, data)
    return response.data
})
export const getNofiticationsExpired = createAsyncThunk('appFinances/getNofiticationsExpired', async data => {   
    const response = await axios.post(`http://api.mfauto.com.ua/notifications/expired-visits?env=${process.env.REACT_APP_NODE_ENV}`, data)
    return response.data
})

// ** Accounts report
export const rentDailyReport = createAsyncThunk('appRents/rentDailyReport', async data => {   
    const response = await axios.post(`http://api.mfauto.com.ua/stats/accounts?env=${process.env.REACT_APP_NODE_ENV}`, data)
    return response.data
})

// ** Balances total by params
export const getPaymentsTotal = createAsyncThunk('appRents/getPaymentsTotal', async data => {   
    const response = await axios.post(`http://api.mfauto.com.ua/finances/total?env=${process.env.REACT_APP_NODE_ENV}`, data)
    return response.data
})

const getLocalStorageFinanceFetchData = () => {
    const storage = window.localStorage.getItem('finances')        
    return storage !== null ? JSON.parse(storage) : {
        expected: 0,
        expired: 0,
        fetch: null
    }
}

export const appFinancesSlice = createSlice({
    name: 'appFinances',
    initialState: {
        expected: [],
        expired: [],
        quantity: getLocalStorageFinanceFetchData(),
        payments: [],
        total: 1,
        params: {}
    },
    reducers: {
        changeExpectedQuantity: (state, action) => {
            state.quantity.expected = action.payload

            const storage = window.localStorage.getItem('finances')      
            const storageParse = storage !== null ? JSON.parse(storage) : {}

            localStorage.setItem('finances', JSON.stringify({                
                expected: action.payload,
                expired: storageParse?.expired,
                fetch: storageParse?.fetch
            }))            
        },
        changeExpiredQuantity: (state, action) => {
            state.quantity.expired = action.payload
            
            const storage = window.localStorage.getItem('finances')      
            const storageParse = storage !== null ? JSON.parse(storage) : {}
            localStorage.setItem('finances', JSON.stringify({                
                expected: storageParse?.expected,
                expired: action.payload,
                fetch: storageParse?.fetch
            }))            
        },
        registerQuantityFetch: (state, action) => {
            state.quantity.fetch = action.payload
            
            const storage = window.localStorage.getItem('finances')      
            const storageParse = storage !== null ? JSON.parse(storage) : {}
            localStorage.setItem('finances', JSON.stringify({                
                expected: storageParse?.expected,
                expired: storageParse?.expired,
                fetch: action.payload
            })) 
        }
    },
    extraReducers: builder => {
        builder
        .addCase(getSystemPayments.fulfilled, (state, action) => {
            state.payments = action.payload.payments
            state.params = action.payload.params
            state.total = action.payload.totalPages
        })       
        .addCase(approvePayment.fulfilled, (state, action) => {     
            if (action.payload.response.status === 200) {
                const updatedPayments = state.payments.map(payment => { 
                    if (payment.payment_id === action.payload.data.payment_id) {                    
                        return {...payment, approve: {...payment.approve, ...action.payload.data}}
                    } else {                    
                        return {...payment}
                    }                
                })
                state.payments = [...updatedPayments]
            }
        }) 
    }
})
export const {
    changeExpectedQuantity,
    changeExpiredQuantity,
    registerQuantityFetch
} = appFinancesSlice.actions

export default appFinancesSlice.reducer
