// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const clearNotifications = createAsyncThunk('appNotifications/clearNotifications', async () => {            
    return {notifications: [], fetch: null}
})

export const getInsuranceNotifications = createAsyncThunk('appNotifications/getInsuranceNotifications', async (data) => {        
    const response = await axios.post(`http://api.mfauto.com.ua/notifications/insurances?env=${process.env.REACT_APP_NODE_ENV}`, data)
    return response.data.insurances
})

export const getRentLastPeriodNotifications = createAsyncThunk('appNotifications/getRentLastPeriodNotifications', async (data) => {        
    const response = await axios.post(`http://api.mfauto.com.ua/notifications/rent-last-period?env=${process.env.REACT_APP_NODE_ENV}`, data)
    return response.data.rents
})

const getLocalStorageNotifications = () => {
    const notifications = window.localStorage.getItem('notifications')        
    return notifications !== null ? (JSON.parse(notifications)).data : []
}

const getLocalStorageLastFetch = () => {
    const fetch = window.localStorage.getItem('notifications')    
    return fetch !== null ? (JSON.parse(fetch)).fetch : null
}

export const appNotifications = createSlice({
    name: 'appNotifications',
    initialState: {
        notifications: getLocalStorageNotifications(),
        fetch: getLocalStorageLastFetch()
    },
    reducers: {},
    extraReducers: builder => {
        builder
        .addCase(clearNotifications.fulfilled, (state, action) => {            
            localStorage.removeItem('notifications')
            state.notifications = action.payload.notifications
            state.fetch = action.payload.fetch
        })
        .addCase(getInsuranceNotifications.fulfilled, (state, action) => {            

            state.fetch = (new Date()).toISOString().split('T')[0]

            const nots = []            

            action.payload.map(item => {
                nots.push({
                    id: item.id,
                    car: {
                        id: item.car.id,
                        name: `${item.car.brand.name} ${item.car.model.name} [${item.car.license_plate}]`
                    },
                    type: item.type,
                    notification: 'insurance',                    
                    end_date: item.end_date
                })                    
            })
            
            state.notifications = [...state.notifications, ...nots].reduce((accumulator, current) => {
                if (!accumulator.find((item) => item.id === current.id)) {
                   accumulator.push(current)
                }
                return accumulator
             }, [])
                        
            localStorage.setItem('notifications', JSON.stringify({
                fetch: state.fetch, 
                data: state.notifications.length > 0 ? state.notifications : []
            }))            
        })
        .addCase(getRentLastPeriodNotifications.fulfilled, (state, action) => {            

            state.fetch = (new Date()).toISOString().split('T')[0]

            const nots = []            

            action.payload.map(item => {
                nots.push({
                    id: item.id,
                    car: {
                        id: item.car.id,
                        name: `${item.car.brand.name} ${item.car.model.name} [${item.car.license_plate}]`
                    },
                    customer: {
                        id: item.customer.id,
                        name: `${item.customer.first_name} ${item.customer.last_name}`
                    },
                    notification: 'rent',
                    end_date: item.end_date
                })                    
            })
            
            state.notifications = [...state.notifications, ...nots]
                        
            localStorage.setItem('notifications', JSON.stringify({
                fetch: state.fetch, 
                data: state.notifications.length > 0 ? state.notifications : []
            }))            
        })
    }
})

export default appNotifications.reducer
